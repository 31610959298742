import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { convertCentsToCurrency } from "../utils"
import { Product } from "../types"

interface ProductListContainerProps {
  product: Product
  additionalProductItem?: boolean
  showCategories?: boolean
}
const ProductListContainer: React.FC<ProductListContainerProps> = ({
  product,
  additionalProductItem,
  showCategories,
}) => {
  return (
    <div
      className={`product-item${
        additionalProductItem ? " additional-product-item" : ""
      }`}
    >
      <Link className="product-container product-link" to={product.path}>
        <GatsbyImage fluid={product.productImage.childImageSharp.fluid} />
        {showCategories && (
          <div
            className={`product-category text-truncate ${product.category.toLowerCase()}`}
          >
            {product.subcategory}
            <span className="text-muted"> - {product.category}</span>
          </div>
        )}
        <div className="product-body">
          <p className="text-truncate">{product.name}</p>
          <h6>${convertCentsToCurrency(product.minPrice)}</h6>
        </div>
      </Link>
    </div>
  )
}

export default ProductListContainer
