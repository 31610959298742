import React from "react"

export default () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 90"
      preserveAspectRatio="none"
      width="100%"
      height="100%"
    >
      <path d="M800 0L0 0L0 75.83C0 75.83 0 75.83 0 75.83C81.75 55.55 167.07 54.32 249.37 72.24C269.95 76.72 227.25 67.42 252.99 73.03C318.39 87.27 386.13 86.93 451.39 72.05C480.2 65.47 470.46 67.7 500 60.96C562.76 46.64 628 47.27 690.48 62.79C705.08 66.42 741.59 75.49 800 90L800 0Z" />
    </svg>
  )
}
