import React from 'react';
import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import Layout from '../components/Layout';
import ProductLinkContainer from '../components/ProductLinkContainer';
import { Product, ProductSubcategory } from '../types';
import { HOME_ROUTE, WEDDING_ROUTE } from '../constants';
import WaveSVG from '../components/WaveSVG';
import { convertCentsToCurrency } from '../utils';

interface ProductListPageComponentProps {
    products: Product[];
    subcategories: ProductSubcategory[];
    category: string;
}

const ProductListPageComponent: React.FC<ProductListPageComponentProps> = ({ products, category, subcategories }) => {
    const pageHeading = `Handmade Creations for Your ${category}`;
    const featuredProducts = products.filter(({ featured }) => featured === true);
    const productSubcategories: { [key: string]: Product[] } = {};
    products.forEach(product => {
        if (!product.featured) {
            if (productSubcategories[product.subcategory]) {
                productSubcategories[product.subcategory].push(product);
            }
            else {
                productSubcategories[product.subcategory] = [product];
            }
        }
    })
    return (
        <div>
            <section className={`page-header-section ${category.toLowerCase()}-section`}>
                <div className="layout-container">
                    <h3 className="margin-bottom-4">{pageHeading}</h3>
                    <h6>Featured Items</h6>
                    <div className="grid-container product-grid flex-center">
                        {featuredProducts.map((product, index) => {
                            return (
                                <div className="featured-item" key={`featured-${index}`}>
                                    <Link className="product-link" to={product.path}>
                                        <div className="product-container">
                                            <GatsbyImage fluid={product.productImage.childImageSharp.fluid} />
                                            <div className="price-container">
                                                <span>${convertCentsToCurrency(product.minPrice)}</span>
                                            </div>
                                        </div>
                                        <p className="text-truncate">{product.name}</p>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="svg-container">
                    <WaveSVG />
                </div>
            </section>
            {subcategories.map(({ value }) => {
                if (productSubcategories[value]) {
                    return (
                        <section key={value} className='padding-bottom-4'>
                            <div className="layout-container">
                                <h6>{value}</h6>
                                <div className="grid-container product-grid">
                                    {productSubcategories[value].map((product, index) => {
                                        return <ProductLinkContainer product={product} key={`product-${index}`}/>
                                    })}
                                </div>
                            </div>
                        </section>
                    )
                }
                return null;
            })}
        </div>
    )
}

const ProductListPage = ({ data: { productSubcategory, allProduct: { nodes } }, pageContext: { category } }) => {
    const pageTitle = `Shop Custom Creations for Your ${category}`;
    const subcategories = productSubcategory.subcategories as ProductSubcategory[];
    return (
        <Layout pageTitle={pageTitle} pathname={category === 'Wedding' ? WEDDING_ROUTE : HOME_ROUTE}>
            <ProductListPageComponent 
                products={nodes as Product[]}
                category={category}
                subcategories={subcategories}
            />
        </Layout>
    )
}

export default ProductListPage;

export const query = graphql`
    query($category: String!) {
        allProduct(sort: {fields: sortRank, order: ASC}, filter: {category: {eq: $category}}) {
            nodes {
                productImage {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                name
                featured
                id
                minPrice
                path
                subcategory
            }
        }
        productSubcategory(id: {eq: "productSubcategories"}) {
            subcategories {
                value
                sortRank
            }
        }
    }
`